import {ElectroDeviceTestingDevice} from './electro-device-testing-device';

export class ElectroDeviceTestingItem {
  id: number;
  vedin?: string;
  barcode?: string;
  nextTestingDateType?: number;
  comment?: string;
  registerNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  location?: string;
  images: [];
  protectionClass?: string;
  deviceCategory?: string;
  deviceType?: string;
  deviceManufacturer?: string;
  nextDGUVV3?: string;
  deviceModel?: string;
  serialNumber?: string;
  installation?: string;
  testingDevice?: ElectroDeviceTestingDevice;
  testingDeviceId?: number;
  isCESymbolExist?: number;
  controlType?: number;
  property?: number; // 0, 1 or 2 currently
  testStatus?: number;
}
